import { render, staticRenderFns } from "./StickyLabelInput.vue?vue&type=template&id=790f2085&scoped=true&"
import script from "./StickyLabelInput.vue?vue&type=script&lang=js&"
export * from "./StickyLabelInput.vue?vue&type=script&lang=js&"
import style0 from "./StickyLabelInput.vue?vue&type=style&index=0&id=790f2085&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "790f2085",
  null
  
)

export default component.exports