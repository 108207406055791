<template>
  <b-modal
    v-model="isModalVisible"
    :title="staticText.deactivateTitle"
    :cancel-title="staticText.cancelLabel"
    :ok-title="staticText.confirmLabel"
    @cancel="$emit('revert-toggle-state')"
    @ok="$emit('deactivate-external-sharing')"
  >
    <template v-slot:modal-header-close>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M8 1.6C4.48 1.6 1.6 4.48 1.6 8c0 3.52 2.88 6.4 6.4 6.4 3.52 0 6.4-2.88 6.4-6.4 0-3.52-2.88-6.4-6.4-6.4zm3.2 8.704l-.896.896L8 8.896 5.696 11.2l-.896-.896L7.104 8 4.8 5.696l.896-.896L8 7.104 10.304 4.8l.896.896L8.896 8l2.304 2.304z" fill="#AAABBA"/></svg>
    </template>
    <b-alert variant="warning" show>
      <p class="m-0">
        {{ staticText.deactivateWarningMessage }}
      </p>
    </b-alert>
  </b-modal>
</template>

<script>

export default {
  name: "DeactivateConfirmationModal",
  data () {
    return {
      isModalVisible: false,
      staticTextDefault: {
        cancelLabel: "Cancel",
        confirmLabel: "Confirm",
        deactivateTitle: "Deactivate Link",
        deactivateWarningMessage: "If you deactivate the link, the call can no longer be accessed via the link."
      }
    }
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  methods: {
    showModal () {
      this.isModalVisible = true
    },
    hideModal () {
      this.isModalVisible = false
    }
  }
}
</script>

<style>

</style>
