<template>
  <div class="multiselect-wrapper-202330101806">
    <div class="multiselect-label">{{ inputLanguageLabel }}</div>
    <bao-vue-multi-select
      :options="languageOptions"
      v-model="selectedLanguage"
      track-by="value"
      label="label"
      :showLabels="false"
      :searchable="false"
      :allow-empty="allowEmpty"
      :loading="isLoading"
      :placeholder="selectedLanguage ? null: placeHolderText"
      @input="$emit('select', selectedLanguage)"
      @open="getAudioInputLanguages"
    >
      <template #noOptions>
        {{ staticText.noOptionsAvailableText }}
      </template>
    </bao-vue-multi-select>

  </div>
</template>

<script>
import axios from "axios"
import BaoVueMultiSelect from "@/apps/base/BaoVueMultiSelect"
import { mapActions } from "vuex"

export default {
  name: "AudioInputLanguageSelector",
  components: {
    BaoVueMultiSelect
  },
  data () {
    return {
      isLoading: false,
      languageOptions: [],
      selectedLanguage: null,
      staticTextDefault: {
        en: "Global English",
        en_au: "English - Australia",
        en_uk: "English - UK",
        en_us: "English - USA",
        de: "German",
        da: "Danish",
        fr: "French",
        es: "Spanish",
        it: "Italian",
        pt: "Portuguese",
        nl: "Dutch",
        hi: "Hindi",
        ja: "Japanese",
        zh: "Chinese",
        fi: "Finnish",
        ko: "Korean",
        pl: "Polish",
        ru: "Russian",
        tr: "Turkish",
        uk: "Ukrainian",
        vi: "Vietnamese",
        inputLanguageLabel: "Standard Language for Audio Input",
        placeHolderText: "Select Audio Input Language",
        noOptionsAvailableText: "No options available"
      }
    }
  },
  props: {
    preSelectedLanguage: {
      type: String,
      required: false
    },
    allowEmpty: {
      default: false,
      type: Boolean,
      required: false
    },
    label: {
      type: String,
      required: false
    },
    placeholder: {
      type: String,
      required: false
    }
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](
        this.$options.name,
        this.staticTextDefault
      )
    },
    inputLanguageLabel () {
      return this.label ? this.label : this.staticText.inputLanguageLabel
    },
    placeHolderText () {
      return this.placeholder ? this.placeholder : this.staticText.placeHolderText
    }
  },
  mounted () {
    if (this.preSelectedLanguage) {
      this.setUserSelectedLanguage()
    }
  },
  methods: {
    ...mapActions({
      getUser: "auth/getUser"
    }),
    updateTranslation () {
      this.getUser().then(() => {
        this.getAudioInputLanguages()
        this.setUserSelectedLanguage()
      })
    },
    setUserSelectedLanguage () {
      this.selectedLanguage = {
        value: this.preSelectedLanguage,
        label: this.staticText[this.preSelectedLanguage]
      }
    },
    transformOptions (options) {
      return options.map(language => {
        return {
          value: language[0], label: this.staticText[language[0]]
        }
      })
    },
    async getAudioInputLanguages () {
      if (this.languageOptions.length) return
      try {
        this.isLoading = true
        const { data } = await axios.get("/api/callAudioData/get_tx_language_options")
        if (data) {
          this.languageOptions = this.transformOptions(data)
        }
        this.isLoading = false
      } catch (err) {
        this.isLoading = false
        console.debug(err.error)
      }
    }
  }
}
</script>

<style scoped lang="scss">

.multiselect-wrapper-202330101806 {
  position: relative;
  z-index: 65;

  .multiselect-label {
    position: absolute;
    top: 3px;
    left: 15px;
    font-size: 12px;
    color: $slate40;
    z-index: 60;
  }
}

</style>

<style lang="scss">
.multiselect-wrapper-202330101806 {

  .multiselect__placeholder {
    padding-left: 7px !important;
  }
}
</style>
