import { render, staticRenderFns } from "./DeactivateConfirmationModal.vue?vue&type=template&id=3540acfc&"
import script from "./DeactivateConfirmationModal.vue?vue&type=script&lang=js&"
export * from "./DeactivateConfirmationModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports