<script>
import VueMultiselect from "vue-multiselect"

export default {
  /**
   This component extends vue-multiselect library and can be used exactly the way vue-multiselect component is used.
   Only some default css behaviours are overwritten according to bao application requirements.
   **/
  name: "BaoVueMultiSelect",
  extends: VueMultiselect
}
</script>

<style scoped lang="scss">

.break-word-no-whit-space {
  white-space: normal !important;
  word-break: break-all !important;
}

.multiselect__single {
  padding-top: unset !important;
  padding-left: 6px !important;
  margin-top: 4px !important;
  overflow-wrap: anywhere;
  @extend .break-word-no-whit-space
}

.multiselect__tags {
  padding: 18px 35px 0 8px;
}

.multiselect__tag {
  background: unset;
  color: unset;
  display: flex;

  span {
    @extend .break-word-no-whit-space
  }

  .multiselect__tag-icon {
    content: url("../../assets/svgs/close-circle.svg");
    width: 16px;

    &:hover, &::after {
      color: unset;
      background: unset;
    }
  }
}

.multiselect__option {
  @extend .break-word-no-whit-space
}

.multiselect__option--highlight::after {
  background: unset;
  color: unset;
}

.multiselect__input {
  margin-left: 6px !important;
  padding-bottom: 6px !important;
  padding-top: unset !important;
}

.multiselect__placeholder {
  padding-left: 8px !important;
  padding-top: unset !important;
}

.multiselect__select {
  padding: 8px !important;
}

</style>
