<template>
  <div class="about-share px-3">
    <div
      role="button"
      class="d-flex justify-content-between align-items-center py-3"
      @click="showAboutShare=!showAboutShare"
    >
      <div class="d-flex about-icon">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:height="'20'" v-bind:width="'20'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 9c0-4.963 4.037-9 9-9s9 4.037 9 9-4.037 9-9 9-9-4.037-9-9zm8.167-3.674l-.01-.01a.543.543 0 01-.129-.362v-.778c0-.135.042-.26.13-.362l.004-.005.005-.005a.513.513 0 01.372-.154h.907c.144 0 .271.053.372.154a.513.513 0 01.154.372v.778c0 .143-.052.27-.154.372l-.004.005-.006.004a.543.543 0 01-.362.13H8.54a.543.543 0 01-.362-.13l-.01-.009zm.115 8.885l-.01-.01a.543.543 0 01-.129-.363V7.013a.49.49 0 01.14-.358.513.513 0 01.371-.153h.692c.139 0 .264.05.358.153a.468.468 0 01.153.358v6.825c0 .144-.052.271-.154.373a.49.49 0 01-.357.139h-.692a.543.543 0 01-.362-.13l-.01-.01z" fill="#35375B" opacity=".6"/></svg>
        <span class="ml-3 call-share-label">
          {{ staticText.aboutShareLabel }}
        </span>
      </div>
      <i
        id="chevron-icon-202408081240"
        :class="getChevronClass"
      ></i>
    </div>
    <b-collapse v-model="showAboutShare">
      <div
        v-for="(item, index) in shareItemsDetails"
        :key="index"
        class="about-item d-flex align-item-center"
      >
        <div class="about-item-icon d-flex">
          <img
            :src=getItemIcon(item.icon)
            height="20"
            width="20"
            svg-inline
          />
        </div>
        <span
          class="ml-2 about-item-text d-flex align-items-center"
        >{{ item.title }}</span>
      </div>
    </b-collapse>
  </div>
</template>

<script>
export default {
  name: "WhatIsShared",
  data () {
    return {
      showAboutShare: false,
      staticTextDefault: {
        aboutShareLabel: "What is shared?",
        recordedConversation: "Recording of the Meeting",
        speechRecognitionMetrics: "Speech Analysis Metrics",
        aiTranscript: "AI Transcript",
        aiSummaries: "AI Summaries",
        clickHistory: "Playbook/Call Documentation"
      }
    }
  },
  props: {
    isInternalSharingTab: {
      type: Boolean
    }
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    shareItemsDetails () {
      const defaultSharedDetails = [
        {
          title: this.staticText.recordedConversation,
          icon: "play-icon-2"
        },
        {
          title: this.staticText.aiTranscript,
          icon: "transcript-2"
        },
        {
          title: this.staticText.aiSummaries,
          icon: "ai-tool"
        }
      ]
      const additionalSharedDetails = [
        {
          title: this.staticText.speechRecognitionMetrics,
          icon: "microphone"
        },
        {
          title: this.staticText.clickHistory,
          icon: "hand-pointer"
        }
      ]
      return this.isInternalSharingTab
        ? defaultSharedDetails.concat(additionalSharedDetails)
        : defaultSharedDetails
    },
    getChevronClass () {
      const defaultClasses = "fas fa-chevron-up chevron-icon"
      return this.showAboutShare
        ? defaultClasses
        : `${defaultClasses} animate-down`
    }
  },
  methods: {
    getItemIcon (icon) {
      return require(`@/assets/svgs/${icon}.svg`)
    }
  }
}
</script>

<style lang="scss" scoped>

.about-share{
    background-color: #F0F0F0;
    border-radius: 12px;
}

.about-item {
  margin-left: 36px;
  margin-top: 12px;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    padding-bottom: 16px;
  }
}

.about-icon {
  svg {
    path {
      fill: #000000;
    }
  }
}

.call-share-label{
  font-size: 16px;
  line-height: 19.2px;
  color: #555775;
}

.about-item-text{
  color: #7F8197;
  font-size: 16px;
  line-height: 19.2px;
}

.about-item-icon{
  svg {
    path {
      fill: #7F8197;
    }
  }
}

.chevron-icon {
  transition: 0.3s ease-in;
  &.animate-down {
    transform: rotate(180deg);
  }
}

</style>
