<template>
  <div class="link-sharing-tab">
    <sticky-label-input
      v-model="emailsToNotify"
      :label="staticText.emailLabel"
      :helper-text="getHelperText"
      :has-error="hasError"
      @input="clearErrorMessage"
    />
    <div class="d-flex justify-content-end mt-3">
      <share-action-button
        :action-label="getLabel"
        :show-success-message="showEmailsSentMessage"
        :show-initial-icon="false"
        :is-loading="isLoading"
        :is-disabled="isDisabled"
        :disabled-label="getDisabledLabel"
        btn-class="btn-primary"
        @trigger-action="validateEmailsAndShareCallDetails"
      />
    </div>
  </div>
</template>

<script>
import ShareActionButton from "./ShareActionButton.vue"
import StickyLabelInput from "@/apps/base/StickyLabelInput.vue"
import axios from "axios"

export default {
  name: "EmailSharingTab",
  components: { ShareActionButton, StickyLabelInput },
  data () {
    return {
      showEmailsSentMessage: false,
      emailsToNotify: "",
      isLoading: false,
      hasError: false,
      staticTextDefault: {
        sendLabel: "Send",
        emailLabel: "E-Mail",
        emailsSent: "E-Mail(s) sent!",
        separateEmailsMessage: "Separate multiple email addresses by commas",
        noEmailMessage: "E-Mail address has to be entered for sending.",
        emailErrorMessage: "Invalid format for E-Mail"
      }
    }
  },
  props: {
    password: {
      type: String,
      required: true
    },
    url: {
      type: String,
      required: true
    },
    hasPasswordError: {
      type: Boolean
    },
    isUserChangingPassword: {
      type: Boolean
    }
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    getLabel () {
      return this.showEmailsSentMessage ? this.staticText.emailsSent : this.staticText.sendLabel
    },
    callId () {
      return Number(this.$route.params.id)
    },
    isDisabled () {
      return !this.password || !this.emailsToNotify || this.isLoading || this.hasError || this.hasPasswordError
    },
    getDisabledLabel () {
      return this.password && !this.emailsToNotify ? this.staticText.noEmailMessage : ""
    },
    getHelperText () {
      return this.hasError ? this.staticText.emailErrorMessage : this.staticText.separateEmailsMessage
    }
  },
  methods: {
    async validateEmailsAndShareCallDetails () {
      if (this.password) {
        const emailsToNotify = this.emailsToNotify.split(",").map(email => email.trim())
        const emailsAreValid = await this.checkIfEmailsAreValid(emailsToNotify)

        if (emailsAreValid) await this.shareCallDetailsViaEmail(emailsToNotify)
        else this.hasError = true
      }
    },
    async shareCallDetailsViaEmail (emailsToNotify) {
      this.isLoading = true

      // If user is currently changing password, we wait till it's done before copying the password
      while (this.isUserChangingPassword) await this.sleep(100)

      if (!this.hasPasswordError) {
        try {
          await axios.post(`/api/calls/${this.callId}/share_externally/send_mails`,
            { email_addresses: emailsToNotify }
          )
          this.isLoading = false
          this.showEmailsSentMessage = true

          await this.sleep(3000)

          this.emailsToNotify = ""
          this.showEmailsSentMessage = false
        } catch (error) {
          console.error(error)
          this.isLoading = false
        }
      } else this.isLoading = false
    },
    async checkIfEmailsAreValid (emailAddresses) {
      for await (const email of emailAddresses) {
        if (!this.isEmailValid(email)) return false
      }
      return true
    },
    clearErrorMessage () {
      this.hasError = false
    }
  }
}
</script>

<style lang="scss" scoped>

.link-sharing-tab {
  padding-top: 16px;
}

</style>
