<template>
  <div>

    <b-btn v-if="errorItem.error"
           class="float-right"
           variant="primary"
           @click="openOAuthWindow(errorItem.code_url, errorItem)"
    >
      <i class="fas fa-sync mr-2"></i>
      {{ staticText.reIntegrateBtnLabel }}
    </b-btn>

    <b-btn v-else
           variant="outline-success"
           class="float-right"
           disabled
    >
      <i class="far fa-check-circle"></i>
    </b-btn>

  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"

export default {
  name: "IntegrationReconnectButton",
  props: {
    errorItem: {
      type: [Object, String],
      required: true
    }
  },
  data () {
    return {
      staticTextDefault: {
        reIntegrateBtnLabel: "Re-Integrate CRM"
      }
    }
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      crmIntegrations: "auth/crmIntegrations"
    }),
    staticText () { return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault) }
  },
  methods: {
    ...mapActions({
      getCRMIntegrationsForUser: "auth/getCrmIntegrationsForUser"
    }),
    openOAuthWindow (url, errorItem) {
      const that = this
      const windowName = "bao_oauth_login_" + Math.floor(Math.random() * 100)
      const sfWindow = window.open(url, windowName, "width=800,height=600")
      try {
        sfWindow.focus()
      } catch (e) {
        this.$refs.navWarningModal.show()
      }
      let existingWin = null

      function checkChild () {
        try {
          existingWin = existingWin || window.open("", windowName)
          if (existingWin.closed) {
            clearInterval(timer)
            that.getCRMIntegrationsForUser().then(() => {
              that.checkError(errorItem)
            })
          }
        } catch (e) {
          /* do nothing */
        }
      }

      const timer = setInterval(checkChild, 250)
    },
    checkError (errorItem) {
      const errorIsGone = !this.crmIntegrations.errors ||
        !Object.values(this.crmIntegrations.errors).some(crm => {
          return crm.code_url === errorItem.code_url
        })
      if (errorIsGone) {
        errorItem.error = null
      }
    }
  }
}
</script>

<style scoped lang="scss">
</style>
