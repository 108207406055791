<template>
  <div class="call-sharing">
    <b-modal
      v-model="showModal"
      body-class="p-0"
      :hide-footer="true"
      @hidden="$emit('close')"
    >
      <template v-slot:modal-header>
        <div class="w-100 d-flex justify-content-between align-items-center py-2">
          <div class="d-flex align-items-center">
            <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:height="'16'" v-bind:width="'16'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M.503 6.327C1.527 3.976 4.293 2.8 8.8 2.8h2.124V.56c0-.152.06-.283.18-.394A.608.608 0 0111.532 0c.165 0 .307.055.427.166l4.855 4.48a.519.519 0 010 .788l-4.855 4.48a.609.609 0 01-.427.167.609.609 0 01-.427-.167.518.518 0 01-.18-.394V7.28H8.801c-.62 0-1.174.018-1.664.053s-.977.097-1.46.188a5.75 5.75 0 00-1.262.372c-.357.157-.691.36-1 .608-.31.248-.564.542-.76.884-.196.341-.349.745-.46 1.212-.11.466-.166.994-.166 1.583 0 .321.016.68.048 1.077 0 .034.008.103.024.205.015.102.023.18.023.232a.31.31 0 01-.08.219.289.289 0 01-.223.087c-.101 0-.19-.05-.266-.149a.99.99 0 01-.123-.192 14.745 14.745 0 01-.228-.473C.402 11.524 0 10.21 0 9.24c0-1.16.168-2.132.503-2.913z" fill="#555775"/></svg>
            <span class="modal-title ml-2">{{staticText.shareCallLabel}}</span>
          </div>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:height="'16'" v-bind:width="'16'" v-bind:class="'cursor-pointer'" v-bind:svg-inline="''" @click="closeShareModal" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M8 1.6C4.48 1.6 1.6 4.48 1.6 8c0 3.52 2.88 6.4 6.4 6.4 3.52 0 6.4-2.88 6.4-6.4 0-3.52-2.88-6.4-6.4-6.4zm3.2 8.704l-.896.896L8 8.896 5.696 11.2l-.896-.896L7.104 8 4.8 5.696l.896-.896L8 7.104 10.304 4.8l.896.896L8.896 8l2.304 2.304z" fill="#AAABBA"/></svg>
        </div>
      </template>
      <div>
        <div
          v-if="isVideoAvailable"
          class="call-sharing__tabs"
        >
          <div
            role="button"
            :class="['call-sharing__tab-button', { active: showInternalSharingTab }]"
            @click="showInternalSharingTab = true"
          >
            {{ staticText.internalLabel }}
          </div>
          <div
            role="button"
            :class="['call-sharing__tab-button', { active: !showInternalSharingTab }]"
            @click="showInternalSharingTab = false"
          >
            {{ staticText.externalLabel }}
          </div>
        </div>
        <what-is-shared
          class="mx-3 mt-3"
          :is-internal-sharing-tab="showInternalSharingTab"
        />
        <internal-call-sharing
          v-if="showInternalSharingTab"
          @close-modal="closeShareModal"
        />
        <external-call-sharing
          v-else
        />
      </div>
    </b-modal>
  </div>
</template>

<script>
import WhatIsShared from "./WhatIsShared.vue"
import InternalCallSharing from "./InternalCallSharing.vue"
import ExternalCallSharing from "./ExternalCallSharing.vue"
import { mapGetters } from "vuex"

export default {
  name: "ShareCallFeedbackModal",
  components: {
    WhatIsShared,
    InternalCallSharing,
    ExternalCallSharing
  },
  props: {
    callId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      showModal: true,
      showInternalSharingTab: true,
      staticTextDefault: {
        shareCallLabel: "Share Call Details",
        internalLabel: "Internal",
        externalLabel: "External"
      }
    }
  },
  mounted () {

  },
  computed: {
    ...mapGetters({
      isVideoAvailable: "callVideoStore/isVideoAvailable"
    }),
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  methods: {
    closeShareModal () {
      this.showModal = false
    }
  }
}

</script>
<style lang="scss">

.modal-title {
  color: $slate80;
}

.call-sharing {
  &__tabs {
    display: flex;
  }
  &__tab-button {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 22.5px;
    border-bottom: 1px solid #dee2e6;
    color: $slate60;
    transition: 0.3s ease-in;
    svg {
      height: 18px;
      width: 18px;
      margin-right: 8px;
      transition: 0.2s ease-in;
      path {
        fill: $slate60;
      }
    }
    &.active {
      border-bottom: 2px solid $slate;
      color: $slate;
      svg {
        path {
          fill: $slate;
        }
      }
    }
    &--small {
      padding: 16.5px;
    }
  }
}

</style>
