<template>
    <div >
        <b-button pill variant="primary" class="pill-btn">
            <template>
                <div class="d-flex px-2 py-1">
                    <span class="pill-title mr-1">{{title}}</span>
                    <img @click="$emit('close', id)" src="@/assets/svgs/close-icon-2.svg" svg-inline>
                </div>
            </template>
        </b-button>
    </div>
</template>

<script>

export default{
    name: "BaoPill",
    props: {
        id:{
            type: [String, Number],
            required: true
        },
        title:{
            type: String,
            required: true
        }
    },
    data() {
        return {
            
        }
    },
}

</script>
<style scoped lang="scss">
.pill-title{
    font-size: 16px;
    line-height: 19.2px;
    color: #555775;
}
.pill-btn{
    padding: 0;
    background-color: #2A2D520F !important;
}
</style>
