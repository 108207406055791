<template>
  <b-modal
    v-model="isModalVisible"
    :title="staticText.regeneratePassword"
    :cancel-title="staticText.cancelLabel"
    :ok-title="staticText.confirmLabel"
    @ok="regeneratePassword"
  >
    <template v-slot:modal-header-close>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M8 1.6C4.48 1.6 1.6 4.48 1.6 8c0 3.52 2.88 6.4 6.4 6.4 3.52 0 6.4-2.88 6.4-6.4 0-3.52-2.88-6.4-6.4-6.4zm3.2 8.704l-.896.896L8 8.896 5.696 11.2l-.896-.896L7.104 8 4.8 5.696l.896-.896L8 7.104 10.304 4.8l.896.896L8.896 8l2.304 2.304z" fill="#AAABBA"/></svg>
    </template>
    <b-alert variant="warning" show>
      <p class="m-0">
        {{ staticText.regeneratePasswordMessage }}
      </p>
    </b-alert>
  </b-modal>
</template>

<script>
import axios from "axios"
import { externalShareBaseUrl } from "@/apps/call_sharing"

export default {
  name: "RegeneratePasswordConfirmation",
  data () {
    return {
      isModalVisible: false,
      staticTextDefault: {
        cancelLabel: "Cancel",
        confirmLabel: "Confirm",
        regeneratePassword: "Regenerate password",
        regeneratePasswordMessage: "Regenerating the password will disable the current one."
      }
    }
  },
  props: {
    externalShareId: {
      type: Number
    }
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  methods: {
    showModal () {
      this.isModalVisible = true
    },
    hideModal () {
      this.isModalVisible = false
    },
    async regeneratePassword () {
      try {
        const { data } = await axios.put(`${externalShareBaseUrl}/${this.externalShareId}/regenerate_password`)
        const updatedData = data.data
        this.$emit("update-external-shared-data", updatedData)
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>

<style>

</style>
