<template>
  <div class="w-100 d-flex justify-content-between align-items-center">

    <slot name="start"></slot>

    <div id="counterparts-name-icons-20228231112"
         class="counterpart"
    >
      <div class="counterpart__avatar">
        <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M11 4a4 4 0 11-8 0 4 4 0 018 0zM0 15.072A5.57 5.57 0 015.572 9.5h2.856A5.57 5.57 0 0114 15.072a.928.928 0 01-.928.928H.928A.928.928 0 010 15.072z" fill="#AAABBA"/></svg>
      </div>
      <div class="counterpart__text">
        <div class="counterpart__title">
          <span class="counterpart__name align-items-center text-left break-word">
            <a
              v-if="!!direct_link"
              :href="direct_link"
              target="_blank"
              :class="{'summary-value': isOpenFromCallViewSummary}"
            >
              {{ name }}
            </a>
            <span v-else>
              {{ name }}
            </span>
          </span>
          <component
            :is="getCrmLogo(crm_service)"
            class="crm-logo crm-logo__slate ml-1"
          />
        </div>
        <span class="counterpart__crm-type">
          {{ type }}
        </span>
      </div>
    </div>

    <slot name="end"></slot>
  </div>
</template>

<script>
import counterpartAppInfo from "@/apps/counterpart/index"
import CrmLogoMixin from "../call_history/CallSummaryComponents/CrmLogoMixin"

export default {
  name: "CounterpartDisplayCell",
  mixins: [CrmLogoMixin],
  props: {
    name: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    crm_service: {
      type: String,
      default: null,
      required: false
    },
    direct_link: {
      type: String,
      default: null,
      required: false
    },
    isOpenFromCallViewSummary: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data () {
    return {
      counterpartAppInfo
    }
  }
}
</script>

<style scoped lang="scss">

.counterpart {
  width: 100%;
  display: flex;
  align-items: center;
  &__avatar {
    width: 36px;
    height: 36px;
    background-color: $slate06;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: 8px;
  }
  &__text {
    width: calc(100% - 44px);
    text-align: left;
  }
  &__title {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
  }
  &__name {
    font-weight: 700;
    color: $slate;
    max-width: calc(100% - 20px);
    .summary-value {
      color: $black80;
    }
  }
  &__crm-type {
    text-transform: capitalize;
    color: $slate60;
  }
}

.reverse-row {
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.break-word {
  word-break: break-word;
}

.summary-value {
  color: $black80;
  font-size: 16px;
  margin-bottom: 0;
  line-height: 14px;
}

</style>
