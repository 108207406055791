<template>
  <div class="external-sharing p-3">
    <div class="activate-link">
      <div class="d-flex align-items-center justify-content-between">
        <span class="activate-title">{{ staticText.activateLinkLabel }}</span>
        <div class="large-switch">
          <b-form-checkbox
            v-model="isExternalShareActive"
            size="lg"
            class="ml-2"
            switch
            @change="attemptToggleShareCallExternally"
          >
          </b-form-checkbox>
        </div>
      </div>
      <div
        v-if="callExternalSharingData"
        class="mt-3"
      >
        <div
          v-if="shouldShowExpiryMessage"
          class="expired-link"
        >
          <div class="expired-message">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M4 0C1.794 0 0 1.794 0 4a4.006 4.006 0 004 4 4.004 4.004 0 003.646-5.646A4.006 4.006 0 004 0zm.006 5.56a.529.529 0 00-.526.52c0 .283.24.52.526.52.276 0 .526-.237.514-.508a.51.51 0 00-.514-.532zM3.48 2.071c0-.314.152-.573.397-.654.246-.067.49.07.596.338a.848.848 0 01.047.3c-.006.15-.015.299-.024.449-.008.15-.017.3-.023.45l-.035.693-.035.694c-.013.15-.013.288-.013.436-.01.247-.175.436-.385.436-.21 0-.373-.177-.385-.421l-.052-1.075-.053-1.075a49.867 49.867 0 01-.035-.57z" fill="#BC0000" fill-opacity=".8"/><path fill-rule="evenodd" clip-rule="evenodd" d="M8.99 3.68a4.974 4.974 0 00-.356-1.562 7.999 7.999 0 017.023 13.539A8 8 0 0110 18c-4.424 0-8-3.6-8-8 0-.461.04-.918.118-1.366a4.974 4.974 0 001.562.356 6.399 6.399 0 105.31-5.31zM9.8 6a.6.6 0 01.6.6v3.6l3.1 1.84a.575.575 0 11-.59.986L9.2 10.8V6.6a.6.6 0 01.6-.6z" fill="#BC0000" fill-opacity=".8"/></svg>
            <div class="d-flex flex-column">
              <span v-html="evalString(staticText.linkExpiredAt)"></span>
              <span>{{ staticText.extendLinkLabel }}</span>
            </div>
          </div>
          <button
            class="btn btn-primary ml-3 d-flex align-items-center justify-content-center"
            :disabled="isProlongLoading"
            @click="prolongExternalShareValidity"
          >
            {{ staticText.prolongLabel }}
            <progress-indicator
              v-if="isProlongLoading"
              class="ml-2 mr-0"
              :small="true"
              :show-loading-label="false"
            />
          </button>
        </div>
        <p
          v-else
          v-html="evalString(staticText.linkExpirationNote)"
          class="expiration-text"
        ></p>
      </div>
    </div>
    <div
      v-if="callExternalSharingData"
      class="mt-3"
    >
      <div class="password-selection">
        <sticky-label-input
          v-model="password"
          class="password-container"
          input-type="password"
          :label="staticText.passwordLabel"
          :has-error="hasError"
          :helper-text="passwordErrorMessage"
          @input="clearErrorMessage"
          @update-input="validateAndChangePassword"
        />
        <div
          role="button"
          class="regenerate-btn"
          @click="regeneratePassword"
        >
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:class="''" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M7.048 13.75a.4.4 0 00.316-.645L4.51 9.409a.4.4 0 00-.633 0l-2.874 3.697a.4.4 0 00.316.645h1.225c.22 0 .4.18.4.4v.787c0 1.965 1.643 3.563 3.664 3.563h7.329c2.02 0 3.664-1.598 3.664-3.563v-.787a.4.4 0 00-.4-.4h-1.643a.4.4 0 00-.4.4v.787c0 .655-.548 1.188-1.221 1.188H6.607c-.673 0-1.221-.533-1.221-1.188v-.787c0-.22.179-.4.4-.4h1.262zM12.952 6.15a.4.4 0 00-.316.645l2.856 3.697a.4.4 0 00.632 0l2.874-3.696a.4.4 0 00-.316-.646h-1.225a.4.4 0 01-.4-.4v-.787c0-1.965-1.643-3.563-3.664-3.563H6.064C4.044 1.4 2.4 2.998 2.4 4.963v.787c0 .221.18.4.4.4h1.643a.4.4 0 00.4-.4v-.787c0-.654.548-1.188 1.221-1.188h7.329c.673 0 1.221.534 1.221 1.188v.787a.4.4 0 01-.4.4h-1.262z" fill="#555775"/></svg>
        </div>
      </div>
      <div>
        <div class="call-sharing__tabs">
          <div
            role="button"
            :class="[getTabButtonClass, { active: shouldShareViaLink }]"
            @click="shouldShareViaLink = true"
          >
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:class="''" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M5.501 10.284l-.567.563a1.27 1.27 0 01-1.781 0 1.234 1.234 0 010-1.753l2.086-2.068c.432-.43 1.245-1.06 1.838-.473a.694.694 0 10.978-.984c-1.007-1-2.496-.815-3.793.472l-2.086 2.07A2.603 2.603 0 001.4 9.97a2.604 2.604 0 00.776 1.86 2.645 2.645 0 001.868.768c.677 0 1.354-.256 1.868-.768l.568-.563a.692.692 0 00-.487-1.185.696.696 0 00-.492.2zm6.323-8.04c-1.083-1.073-2.597-1.131-3.598-.137l-.706.701a.693.693 0 00.977.985l.707-.701c.518-.515 1.198-.302 1.643.138a1.23 1.23 0 010 1.752L8.62 7.19c-1.018 1.008-1.496.536-1.7.334a.693.693 0 00-.977.984c.468.463 1.001.693 1.56.693.684 0 1.407-.345 2.095-1.027l2.226-2.207a2.613 2.613 0 00.775-1.86 2.608 2.608 0 00-.776-1.862z" fill="#2A2D52" fill-opacity=".8"/></svg>
            {{ staticText.shareViaLinkLabel }}
          </div>
          <div
            role="button"
            :class="[getTabButtonClass, { active: !shouldShareViaLink }]"
            @click="shouldShareViaLink = false"
          >
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:class="''" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M13.934 2.516H2.066a1.25 1.25 0 00-.543.126l6.449 6.449L9.417 7.7l5.06-5.058a1.25 1.25 0 00-.543-.127zM15.073 3.24L10.313 8l4.76 4.761c.08-.165.127-.347.127-.542V3.78a1.25 1.25 0 00-.127-.542zM.927 3.24A1.25 1.25 0 00.8 3.78v8.438c0 .195.048.377.127.542l4.76-4.76-4.76-4.762z" fill="#555775"/><path d="M9.716 8.597L8.27 9.986a.42.42 0 01-.596 0l-1.39-1.39-4.76 4.761c.164.08.347.128.542.128h11.868c.195 0 .378-.048.543-.127L9.716 8.597z" fill="#555775"/></svg>
            {{ staticText.shareViaEmailLabel }}
          </div>
        </div>
        <link-sharing-tab
          v-if="shouldShareViaLink"
          :password="password"
          :url="getExternalShareUrl"
          :is-user-changing-password="isUserChangingPassword"
          :has-password-error="hasError"
        />
        <email-sharing-tab
          v-else
          :password="password"
          :url="getExternalShareUrl"
          :has-password-error="hasError"
          :is-user-changing-password="isUserChangingPassword"
        />
      </div>
    </div>
    <deactivate-confirmation-modal
      ref="deactivate-confirmation-modal"
      @revert-toggle-state="isExternalShareActive = true"
      @deactivate-external-sharing="toggleShareCallExternally"
    />
    <regenerate-password-confirmation
      v-if="callExternalSharingData"
      ref="regenerate-password-confirmation"
      :external-share-id=externalShareId
      @update-external-shared-data="updateAndPopulateExternalSharingData"
    />
  </div>
</template>

<script>
import axios from "axios"
import moment from "moment"

import { mapActions, mapGetters } from "vuex"
import EmailSharingTab from "./EmailSharingTab.vue"
import LinkSharingTab from "./LinkSharingTab.vue"
import StickyLabelInput from "@/apps/base/StickyLabelInput.vue"
import DeactivateConfirmationModal from "./DeactivateConfirmationModal.vue"
import RegeneratePasswordConfirmation from "./RegeneratePasswordConfirmation.vue"
import ProgressIndicator from "@/apps/base/ProgressIndicator.vue"
import { externalShareComponentUrl, externalShareBaseUrl } from "@/apps/call_sharing"

export default {
  components: {
    LinkSharingTab,
    EmailSharingTab,
    StickyLabelInput,
    DeactivateConfirmationModal,
    RegeneratePasswordConfirmation,
    ProgressIndicator
  },
  name: "ExternalCallSharing",
  data () {
    return {
      password: "",
      shouldShareViaLink: true,
      isPasswordInput: true,
      isExternalShareActive: false,
      isUserChangingPassword: false,
      hasError: false,
      passwordErrorMessage: "",
      isProlongLoading: false,
      staticTextDefault: {
        activateLinkLabel: "Activate link to share",
        linkExpirationNote: "This link is valid for {{ getExpiryMessageValue }} (until {{ getFormattedExpirationDate }}). When it expires, the link will no longer be active. You can reactivate it manually.",
        shareViaLinkLabel: "Share via Link",
        shareViaEmailLabel: "Share via E-Mail",
        passwordLabel: "Password",
        prolongLabel: "Prolong",
        linkExpiredAt: "The link expired at {{ getFormattedExpirationDate }}.",
        extendLinkLabel: "To extend it for another 30 days, click on the button.",
        shortPasswordMessage: "Password must contain at least 6 characters",
        daysLabel: "day(s)",
        hoursLabel: "hour(s)",
        minutesLabel: "minute(s)",
        secondsLabel: "second(s)"
      }
    }
  },
  mounted () {
    this.populateExternalSharingData()
  },
  computed: {
    ...mapGetters({
      callExternalSharingData: "callSummaryStore/getCallExternalSharingData"
    }),
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    callId () {
      return Number(this.$route.params.id)
    },
    externalShareId () {
      return this.callExternalSharingData.id
    },
    getExternalShareUrl () {
      return `${window.location.origin}${externalShareComponentUrl}/${this.callExternalSharingData.url_id}`
    },
    daysLeftTillExpiry () {
      return this.getExpirationDate.diff(this.getCurrentDate, "days")
    },
    hoursLeftTillExpiry () {
      return this.getExpirationDate.diff(this.getCurrentDate, "hours")
    },
    minutesLeftTillExpiry () {
      return this.getExpirationDate.diff(this.getCurrentDate, "minutes")
    },
    secondsLeftTillExpiry () {
      return this.getExpirationDate.diff(this.getCurrentDate, "seconds")
    },
    shouldShowExpiryMessage () {
      return this.secondsLeftTillExpiry < 0
    },
    getExpiryMessageValue () {
      if (this.daysLeftTillExpiry > 0) return `${this.daysLeftTillExpiry} ${this.staticText.daysLabel}`
      else if (this.hoursLeftTillExpiry > 0) return `${this.hoursLeftTillExpiry} ${this.staticText.hoursLabel}`
      else if (this.minutesLeftTillExpiry > 0) return `${this.minutesLeftTillExpiry} ${this.staticText.minutesLabel}`
      else return `${this.secondsLeftTillExpiry} ${this.staticText.secondsLabel}`
    },
    getCurrentDate () {
      return new Date()
    },
    getExpirationDate () {
      const expirationDate = this.callExternalSharingData.expires_at
      return moment(expirationDate)
    },
    getFormattedExpirationDate () {
      return moment(this.getExpirationDate).format("DD.MM.YYYY HH:mm")
    },
    getTabButtonClass () {
      return "call-sharing__tab-button call-sharing__tab-button--small"
    }
  },
  methods: {
    ...mapActions({
      updateCallExternalSharingData: "callSummaryStore/updateCallExternalSharingData"
    }),
    populateExternalSharingData () {
      this.isExternalShareActive = !!this.callExternalSharingData
      if (this.callExternalSharingData) this.password = this.callExternalSharingData.decrypted_password
    },
    async attemptToggleShareCallExternally (toggleValue) {
      if (toggleValue) await this.toggleShareCallExternally()
      else this.$refs["deactivate-confirmation-modal"].showModal()
    },
    updateAndPopulateExternalSharingData (updatedData) {
      this.updateCallExternalSharingData(updatedData)
      this.populateExternalSharingData()
    },
    async toggleShareCallExternally () {
      try {
        const { data } = await axios.put(`/api/calls/${this.callId}/share_externally`)
        this.updateAndPopulateExternalSharingData(data)
      } catch (error) {
        console.error(error)
        this.populateExternalSharingData()
      }
    },
    async prolongExternalShareValidity () {
      this.isProlongLoading = true
      try {
        const { data } = await axios.put(`${externalShareBaseUrl}/${this.externalShareId}/prolong_expiry`)
        const updatedData = data.data
        this.updateAndPopulateExternalSharingData(updatedData)
        this.isProlongLoading = false
      } catch (error) {
        console.error(error)
        this.isProlongLoading = false
      }
    },
    async regeneratePassword () {
      this.$refs["regenerate-password-confirmation"].showModal()
    },
    async validateAndChangePassword () {
      if (this.password !== this.callExternalSharingData.decrypted_password) {
        this.isUserChangingPassword = true
        const isPasswordValid = this.validatePassword()
        if (isPasswordValid) await this.changePassword()
        this.isUserChangingPassword = false
      }
    },
    async changePassword () {
      try {
        const { data } = await axios.put(`${externalShareBaseUrl}/${this.externalShareId}/update_password`, {
          password: this.password
        })
        const updatedData = data.data
        this.updateAndPopulateExternalSharingData(updatedData)
      } catch (error) {
        console.error(error)
      }
    },
    validatePassword () {
      if (this.password.length < 6) {
        this.handlePasswordError()
        return false
      }
      return true
    },
    handlePasswordError () {
      this.passwordErrorMessage = this.staticText.shortPasswordMessage
      this.hasError = true
    },
    clearErrorMessage () {
      this.hasError = false
      this.passwordErrorMessage = ""
    }
  }
}
</script>

<style lang="scss" scoped>

.external-sharing {
  .activate-link {
    .activate-title {
      color: $slate;
    }

    p.expiration-text {
      color: $slate60;
      font-size: 14px;
      line-height: 16.8px;
      margin: 0;
    }

    .expired-link {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #BC00001A;
      padding: 16px;
      border-radius: 8px;

      .expired-message {
        display: flex;
        align-items: center;
        svg {
          width: 20px;
          height: 20px;
          margin-right: 16px;
        }
        div {
          width: calc(100% - 36px);
        }
      }

      span {
        color: #BC0000CC;
      }
    }
  }

  .password-selection {
    width: 100%;
    display: flex;
    margin-bottom: 16px;
    .password-container {
      width: calc(100% - 56px - 8px); // width of regenerate btn + margin
      margin-right: 8px;
    }
    .regenerate-btn {
      width: 56px;
      height: 56px;
      display: flex;
      svg {
        width: 19.48px;
        height: 19.48px;
        path {
          fill: $slate80;
        }
      }
    }
  }

  .large-switch {
    :deep(.custom-control-label) {
      padding: 0 13px 0 0;
    }
  }
}

</style>
