<template>

  <div v-if="errors.length > 0">
    <b-btn v-if="errors"
           variant="outline-danger"
           size="sm"
           :disabled="disabled"
           :class="extraClass"
           @click="showErrorModal=true"
    >
      <i class="fas fa-exclamation-triangle fa-xs"></i>
      {{ buttonText }}
    </b-btn>

    <b-modal id="error-modal-180120211545"
             v-model="showErrorModal"
             :title="title"
             :ok-title="staticText.okLabel"
             header-border-variant="danger"
             header-text-variant="danger"
             size="lg"
             ok-only
             @ok="$emit('Done')"
    >
      <p v-html="errorHeaderText"></p>

      <slot></slot>

      <div v-for="(error, index) in errors"
           :key="index"
      >
        <ul class="container">
          <li v-for="(item, key) in error"
              :key="index+key"
          >
            <div class="mt-2 p-2 d-flex justify-content-between">
              <div>
                {{ key }}:
                <span v-if="item.error">
                  {{ staticText.IntegrationErrorMsgLabel }}
                </span>

                <span v-else>
                  {{ staticText.IntegrationSuccessMsgLabel }}
                </span>
              </div>

              <div>
                <IntegrationReconnectButton :errorItem="item"></IntegrationReconnectButton>
              </div>
            </div>

            <div>
              <a href="#"
                 v-b-toggle="'error-details-' + index+key"
              >
                {{ staticText.showDetails}}
              </a>

              <b-collapse :id="'error-details-' + index+key">
                <div class="d-flex justify-content-between">
                  <pre>{{ item }}</pre>

                  <div class="my-auto">
                    <b-btn size="sm"
                           variant="light"
                           class="ml-2"
                           v-clipboard:copy="JSON.stringify(item)"
                    >
                      {{ staticText.copy}}
                    </b-btn>
                  </div>
                </div>
              </b-collapse>
            </div>
          </li>
        </ul>
      </div>
    </b-modal>
  </div>
</template>

<script>
import IntegrationReconnectButton from "../user/IntegrationReconnectButton"

export default {
  name: "ErrorModal",
  components: {
    IntegrationReconnectButton
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    buttonText: {
      type: String,
      default: "Error"
    },
    title: {
      type: String,
      default: "Error"
    },
    errors: {
      type: Array
    },
    launchErrorModal: {
      type: Boolean,
      default: false
    },
    errorHeaderText: {
      type: String,
      default: "It looks like something went wrong. Reload the page and try again.\n" +
        "      If the problem persist please contact your system administrator."
    },
    extraClass: {
      required: false,
      default: "",
      type: String
    }
  },
  data () {
    return {
      showErrorModal: false,
      staticTextDefault: {
        okLabel: "Done",
        IntegrationErrorMsgLabel:
          "CRM access timed-out. Before continuing please re-integrate with your CRM.",
        IntegrationSuccessMsgLabel: "Successfully re-integrated with CRM services.",
        showDetails: "Show details",
        copy: "Copy"
      }
    }
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](
        this.$options.name,
        this.staticTextDefault
      )
    }
  },
  created () {
    this.showErrorModal = this.launchErrorModal
  }
}
</script>

<style scoped lang="scss">

</style>
