<template>
  <div :class="getContainerClass">
    <div class="input-container">
      <b-form-input
        id="sticky-label-input"
        v-model="inputValue"
        :type="getInputType"
        :class="getInputClass"
        :autofocus="autofocus"
        @input="$emit('input', inputValue)"
        @blur="$emit('update-input', inputValue)"
      ></b-form-input>
      <label
        class="placeholder"
        for="sticky-label-input"
      >{{ label }}</label>

      <div
        v-if="isPasswordInput"
        class="right-absolutes"
      >
        <span
          v-if="hasError"
          class="clear-input"
          role="button"
          @click="clearAndRefocusInput"
        >
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M8 1.6C4.48 1.6 1.6 4.48 1.6 8c0 3.52 2.88 6.4 6.4 6.4 3.52 0 6.4-2.88 6.4-6.4 0-3.52-2.88-6.4-6.4-6.4zm3.2 8.704l-.896.896L8 8.896 5.696 11.2l-.896-.896L7.104 8 4.8 5.696l.896-.896L8 7.104 10.304 4.8l.896.896L8.896 8l2.304 2.304z" fill="#AAABBA"/></svg>
        </span>
        <span
          class="toggle-password"
          role="button"
          @click="toggleInputType"
        >
          <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#7F8197" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M251.6 185.7c-36.9 0-67 31.5-67 70.3 0 38.7 30 70.3 67 70.3 36.9 0 67-31.5 67-70.3 0-38.7-30.1-70.3-67-70.3z"/><path d="M251.6 367.1c-59.4 0-107.8-49.8-107.8-111.1s48.4-111.1 107.8-111.1S359.4 194.7 359.4 256 311 367.1 251.6 367.1zm246.3-121.9C434.1 142.8 348.1 86.4 256 86.4S77.9 142.8 14.1 245.2c-4.1 6.6-4.1 15 0 21.6C77.9 369.2 163.9 425.6 256 425.6s178-56.4 241.9-158.8c4.1-6.6 4.1-15 0-21.6z"/></svg>
        </span>
      </div>
    </div>
    <p
      v-if="helperText"
      class="helper-text"
    >{{ helperText }}</p>
  </div>
</template>

<script>
export default {
  name: "StickyLabelInput",
  data () {
    return {
      inputValue: "",
      showPasswordInput: false
    }
  },
  props: {
    value: {
      type: String,
      required: true
    },
    inputType: {
      type: String,
      default: "text"
    },
    helperText: {
      type: String
    },
    label: {
      type: String
    },
    hasError: {
      type: Boolean
    },
    autofocus: {
      type: Boolean
    }
  },
  mounted () {
    this.inputValue = this.value
  },
  watch: {
    value (val) {
      this.inputValue = val
    }
  },
  computed: {
    getInputType () {
      return this.isPasswordInput && this.showPasswordInput ? "password" : "text"
    },
    getContainerClass () {
      const defaultClasses = "sticky-label-input"
      return this.hasError ? `${defaultClasses} has-error` : defaultClasses
    },
    getInputClass () {
      const defaultClasses = ""
      let extraClasses = ""
      if (this.value) extraClasses += " is-active"
      if (this.isPasswordInput) {
        extraClasses += this.hasError ? " xl-r-padding" : " l-r-padding"
      }
      return `${defaultClasses} ${extraClasses}`
    },
    isPasswordInput () {
      return this.inputType === "password"
    }
  },
  methods: {
    toggleInputType () {
      this.showPasswordInput = !this.showPasswordInput
    },
    clearAndRefocusInput () {
      this.inputValue = ""
      this.$emit("input", this.inputValue)
      document.getElementById("sticky-label-input").focus()
    }
  }
}
</script>

<style lang="scss" scoped>

.sticky-label-input {
  .input-container {
    position: relative;
    label.placeholder {
      position: absolute;
      top: 21px;
      left: 24px;
      line-height: 19.2px;
      color: $slate60;
      transition: 0.2s linear;
    }

    input {
      padding: 21px 24px 16px;
      outline: none;
      box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.05);
      background: rgba(255, 255, 255, 0.8);
      transition: 0.3s ease-in;
      &:active,
      &:focus,
      &.is-active {
        padding: 28px 24px 9px;
        & + label.placeholder {
          top: 11px;
          font-size: 12px;
          line-height: 14.4px;
          color: $slate40;
        }
      }
      &.l-r-padding {
        padding-right: 53px;
      }
      &.xl-r-padding {
        padding-right: 75px;
      }
      &::-ms-reveal,
      &::-ms-clear {
          display: none;
      }
    }

    div.right-absolutes {
      position: absolute;
      top: 0;
      right: 24px;
      height: 100%;
      display: flex;
      align-items: center;
      margin: auto 0;

      span.clear-input {
        margin-right: 8px;
        svg {
          height: 20px;
          width: 20px;
          path {
            fill: #BC0000;
          }
        }
      }

      span.toggle-password {
        svg {
          height: 20px;
          width: 20px;
          path {
            fill: $slate80;
          }
        }
      }
    }
  }
  p.helper-text {
    margin: 16px 0 0;
    color: $slate60;
    font-size: 14px;
    line-height: 16.8px;
  }
  &.has-error {
    input {
      border: 1px solid #BC0000CC;
    }
    p.helper-text {
      margin: 8px 0 0;
      color: #BC0000;
      text-align: left;
      font-size: 12px;
      line-height: 14.4px;
    }
  }
}

</style>
