<template>
  <div class="d-flex mx-0 mb-3">
    <div
      v-if="isPlayerReady"
      class="d-flex flex-column justify-content-between px-4 py-4 audio-controls-container">
      <div class="d-flex justify-content-between align-items-center mb-2">
        <div class="media-control">
          <button class="media-control-btn" @click="wavesurfer.skip(-15)">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M2.5 9a.47.47 0 00-.48.5A6 6 0 108 3H6v-.932a.5.5 0 00-.82-.385L3.486 3.095a.5.5 0 00.02.784L5.2 5.15a.5.5 0 00.8-.4V4h2a5 5 0 11-4.975 5.5A.542.542 0 002.5 9z" fill="#555775"/><path d="M6.914 7H6.17c-.144.528-.372.66-1.17.666v.552h1.056v2.742h.858V7zM9.173 7.702h1.632V7H8.537l-.246 2.19h.828a.64.64 0 01.462-.192c.348 0 .564.252.564.648 0 .414-.228.69-.582.69-.294 0-.486-.18-.528-.498h-.864c.084.768.576 1.2 1.386 1.2.882 0 1.44-.546 1.44-1.398 0-.798-.516-1.32-1.302-1.32-.222 0-.39.03-.606.108l.084-.726z" fill="#555775"/></svg>
          </button>
        </div>
        <div class="media-control mx-2">
          <button class="media-control-btn play-pause-btn" @click="wavesurfer.playPause()">
            <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="isPlaying" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M1.5 0A1.5 1.5 0 000 1.5v13a1.5 1.5 0 003 0v-13A1.5 1.5 0 001.5 0zm9 0A1.5 1.5 0 009 1.5v13a1.5 1.5 0 003 0v-13A1.5 1.5 0 0010.5 0z" fill="#35375B"/></svg>
            <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg" v-else="" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M2.235.192A1.5 1.5 0 000 1.5v9a1.5 1.5 0 002.235 1.307l8-4.5a1.5 1.5 0 000-2.614l-8-4.5V.192z" fill="#2A2D52"/></svg>
          </button>
        </div>
        <div class="media-control">
          <button class="media-control-btn" @click="wavesurfer.skip(15)">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M13.5 9a.47.47 0 01.48.5A6 6 0 118 3h2v-.932a.5.5 0 01.82-.385l1.694 1.412a.5.5 0 01-.02.784L10.8 5.15a.5.5 0 01-.8-.4V4H8a5 5 0 104.975 5.5.542.542 0 01.525-.5z" fill="#555775"/><path d="M6.914 7H6.17c-.144.528-.372.66-1.17.666v.552h1.056v2.742h.858V7zM9.173 7.702h1.632V7H8.537l-.246 2.19h.828a.64.64 0 01.462-.192c.348 0 .564.252.564.648 0 .414-.228.69-.582.69-.294 0-.486-.18-.528-.498h-.864c.084.768.576 1.2 1.386 1.2.882 0 1.44-.546 1.44-1.398 0-.798-.516-1.32-1.302-1.32-.222 0-.39.03-.606.108l.084-.726z" fill="#555775"/></svg>
          </button>
        </div>
      </div>
      <div class="audio-progress-text d-flex justify-content-center">
        {{ baoAudioCurrentTime }}/{{ this.callDuration }}
      </div>
      <div class="audio-speed d-flex justify-content-center">
        <b-form-group class="position-relative">
          <b-form-select
            ref="audioSpeedSelect"
            id="audio-speed-202406241028"
            v-model="audioPlaybackRate"
            class="h-100 custom-form-select"
            :options="audioPlaybackRateList"
            @change="changeAudioPlaybackRate"
            plain
          >
          </b-form-select>
          <img
            src="@/assets/svgs/speedometer.svg"
            class="speedometer-icon"
            alt="Playback Speed"
            @click="triggerSelect"
          />
        </b-form-group>
      </div>
    </div>
    <div v-if="isPlayerReady" class="vertical-divider"></div>
    <div class="p-4 audio-wave w-100" :class="{'audio-loading-border': !isPlayerReady}">
      <div>
        <div class="d-flex align-items-center justify-content-end">
          <div class="actionGroup mr-3" @click="shareBaoAudio">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" :id="`share-bao-wave-btn-${index}`" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M5.501 10.284l-.567.563a1.27 1.27 0 01-1.781 0 1.234 1.234 0 010-1.753l2.086-2.068c.432-.43 1.245-1.06 1.838-.473a.694.694 0 10.978-.984c-1.007-1-2.496-.815-3.793.472l-2.086 2.07A2.603 2.603 0 001.4 9.97a2.604 2.604 0 00.776 1.86 2.645 2.645 0 001.868.768c.677 0 1.354-.256 1.868-.768l.568-.563a.692.692 0 00-.487-1.185.696.696 0 00-.492.2zm6.323-8.04c-1.083-1.073-2.597-1.131-3.598-.137l-.706.701a.693.693 0 00.977.985l.707-.701c.518-.515 1.198-.302 1.643.138a1.23 1.23 0 010 1.752L8.62 7.19c-1.018 1.008-1.496.536-1.7.334a.693.693 0 00-.977.984c.468.463 1.001.693 1.56.693.684 0 1.407-.345 2.095-1.027l2.226-2.207a2.613 2.613 0 00.775-1.86 2.608 2.608 0 00-.776-1.862z" fill="#2A2D52" fill-opacity=".8"/></svg>
            <b-tooltip
              :target="`share-bao-wave-btn-${index}`"
              :title="isBaoWaveShared ? staticText.baoWaveSharedAlertLabel : staticText.shareBaoWaveLabel"
              :delay="{ show: 100, hide: 400 }"
              triggers="hover"
            ></b-tooltip>
          </div>
          <div class="actionGroup" @click="downloadBaoAudio(callSegment)">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" :id="`download-bao-wave-btn-${index}`" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M0 10.875a.5.5 0 01.5-.5h11a.5.5 0 01.5.5v.625a.5.5 0 01-.5.5H.5a.5.5 0 01-.5-.5v-.625zM8.208.5a.5.5 0 00-.5-.5H4.292a.5.5 0 00-.5.5v4.466h-1.49a.5.5 0 00-.34.866L6 9.592l4.038-3.76a.5.5 0 00-.34-.866h-1.49V.5z" fill="#35375B" opacity=".8"/></svg>
            <b-tooltip
              :target="`download-bao-wave-btn-${index}`"
              :title="isBaoWaveDownloading ? staticText.baoWaveDownloadingAlertLabel : staticText.downloadBaoWaveLabel"
              :delay="{ show: 100, hide: 400 }"
              triggers="hover"
            ></b-tooltip>
          </div>
        </div>
        <div
          class="cursor-pointer"
          :class="{ 'baowave-hidden' : !isPlayerReady }"
          :id="`baoAudioWave-${callSegment.id}`"
        ></div>
        <div v-if="!isPlayerReady">
          <ProgressIndicator :loading-label="staticText.baoWaveRenderingLabel" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WaveSurfer from "wavesurfer.js"
import axios from "axios"
import ProgressIndicator from "@/apps/base/ProgressIndicator"

export default {
  name: "BaoAudioPlayer",
  components: {
    ProgressIndicator
  },
  props: {
    index: {
      default: 0
    },
    callSegment: {
      type: Object,
      default: () => {}
    },
    seekTime: { // in seconds
      type: Number,
      default: null,
      required: false
    },
    getDisplayTimeStamp: {
      type: Function,
      required: true
    }
  },
  data () {
    return {
      wavesurfer: null,
      currentTime: 0,
      callDuration: "00:00",
      audio_url: null,
      isPlayerReady: false,
      isPlaying: false,
      isBaoWaveShared: false,
      isBaoWaveDownloading: false,
      audioPlaybackRate: 1,
      audioPlaybackRateList: [
        { value: 0.5, text: "0.5x" },
        { value: 1, text: "1x" },
        { value: 1.25, text: "1.25x" },
        { value: 1.5, text: "1.5x" },
        { value: 1.75, text: "1.75x" },
        { value: 2, text: "2x" }
      ],
      staticTextDefault: {
        baoWaveSharedAlertLabel: "Link copied",
        shareBaoWaveLabel: "Copy Link",
        downloadBaoWaveLabel: "Download",
        baoWaveDownloadingAlertLabel: "Downloading...",
        baoWaveRenderingLabel: "Rendering audio..."
      }
    }
  },
  watch: {
    seekTime (val, oldVal) {
      if (val !== null && val !== oldVal) {
        this.wavesurfer.seekTo(val / this.wavesurfer.getDuration())
      }
    }
  },
  computed: {
    baoAudioCurrentTime () {
      if (!this.wavesurfer) return "0:00"
      return this.getDisplayTimeStamp(this.currentTime * 1000)
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  mounted () {
    this.updateAudioURL()
    this.launchBaoAudio()
  },
  methods: {
    changeAudioPlaybackRate () {
      this.wavesurfer.setPlaybackRate(this.audioPlaybackRate, true)
    },
    triggerSelect () {
      this.$refs.audioSpeedSelect.$el.showPicker()
    },
    updateAudioURL () {
      this.audio_url = this.callSegment.final_audio_url || this.callSegment.call_audio_url
    },
    launchBaoAudio () {
      this.wavesurfer = WaveSurfer.create({
        container: `#baoAudioWave-${this.callSegment.id}`,
        fillParent: true,
        normalize: true,
        barWidth: 1,
        barRadius: 1,
        backend: "MediaElement",
        waveColor: "#2A2D52",
        progressColor: "#FF5632",
        cursorColor: "#35375B"
      })
      this.wavesurfer.load(this.audio_url)
      this.wavesurfer.on("audioprocess", () => {
        this.currentTime = this.wavesurfer.getCurrentTime()
        this.$emit("time-update", this.currentTime)
      })
      this.wavesurfer.on("ready", () => {
        this.isPlayerReady = true
        this.callDuration = this.getDisplayTimeStamp(this.wavesurfer.getDuration() * 1000)
      })
      // Following code is to avoid Chrome browser crash (Firefox is legendary):
      // We check after 5 seconds after page load if the player is ready (waveform is created)
      // If not we wait for 5 seconds more and then kill the waveform creating process
      // This renders waveform created by 10 seconds of process.
      // Therefore duration waveform created strictly depends on how much client's chrome browser
      // can create it in 10 seconds and then displays it and THEN player is available to play.
      if (navigator.userAgent.match(/chrome|chromium|crios/i)) {
        setTimeout(() => {
          setTimeout(() => {
            if (!this.isPlayerReady) {
              const currentTime = this.wavesurfer.getCurrentTime()
              this.wavesurfer.empty()
              this.wavesurfer.play(currentTime)
            }
          }, 5000)
        }, 5000)
      }
      this.wavesurfer.on("seeking", () => {
        this.currentTime = this.wavesurfer.getCurrentTime()
        this.$emit("time-update", this.currentTime)
      })
      this.wavesurfer.on("play", () => {
        this.isPlaying = true
      })
      this.wavesurfer.on("pause", () => {
        this.isPlaying = false
      })
    },
    shareBaoAudio () {
      this.$copyText(window.location.href).then(async () => {
        this.isBaoWaveShared = true
        await this.sleep(2000)
        this.isBaoWaveShared = false
      })
    },
    downloadBaoAudio (callSegment) {
      this.isBaoWaveDownloading = true
      axios.get(this.audio_url, { responseType: "blob" })
        .then(response => {
          const blob = new Blob([response.data], { type: "audio/mp3" })
          const link = document.createElement("a")
          link.href = URL.createObjectURL(blob)
          link.download = callSegment.call_id
          link.click()
          URL.revokeObjectURL(link.href)
          this.isBaoWaveDownloading = false
        }).catch(error => {
          this.isBaoWaveDownloading = false
          console.error(error.response.data)
        })
    }
  },
  beforeDestroy () {
    this.wavesurfer.destroy()
  }
}
</script>

<style scoped lang="scss">

.custom-form-select{
  -webkit-appearance: none;
  -moz-appearance: none;
  box-shadow: none;
  background-color: #EFEFEF;
  color: #555775;
  padding: 7px 7px 7px 25px !important;
  line-height: 14px;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
}

.audio-controls-container {
  background-color: white;
  padding: 20px 16px;
  border-radius: 20px 0 0 20px;
}
.audio-speed {
  :deep(.form-group){
    margin-bottom: 0;
  }
}

.speedometer-icon {
  position: absolute;
  left: 8px;
  height: 12px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  pointer-events: auto;
}

.audio-progress-text{
  font-size: 16px;
  line-height: 14px;
  color: #555775;
}
.vertical-divider {
  width: 2px;
  height: 190px;
  background-color: #2A2D521A;
}
.play-pause-btn{
  width: 48px;
  height: 48px;
  svg {
    path {
      fill: #555775;
    }
  }
  &:hover {
    svg {
      path {
        fill: #FF5632;
      }
    }
  }
}

.audio-loading-border{
  border-radius: 20px;
}

</style>
