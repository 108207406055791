<template>
  <div
    :id="linkContainerId"
    :class="getLinkContainerClass"
    @click="copyExternalShareUrl"
  >
    <div class="d-flex flex-column overflow-hidden">
      <span class="link-label pb-1">Link</span>
      <span class="link-url">{{ url }}</span>
    </div>
    <div
      v-if="showCopyButton"
      :id="copyButtonId"
      class="copy-btn d-flex align-items-center"
      @click="copyCallUrl()"
    >
      <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:class="'cursor-pointer'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M5.85 12.5A2.553 2.553 0 013.3 9.95V3.8H2.25C1.34 3.8.6 4.54.6 5.45v8.1c0 .91.74 1.65 1.65 1.65h7.5c.91 0 1.65-.74 1.65-1.65V12.5H5.85z" fill="#2A2D52" fill-opacity=".8"/><path d="M13.8 2.45A1.65 1.65 0 0012.15.8h-6.3A1.65 1.65 0 004.2 2.45v7.5c0 .911.739 1.65 1.65 1.65h6.3a1.65 1.65 0 001.65-1.65v-7.5z" fill="#2A2D52" fill-opacity=".8"/></svg>
    </div>
    <b-tooltip
      :target="getTooltipTarget"
      :title="isTextCopied ? staticText.copiedCallLabel: staticText.copyCallLabel"
      :delay="{ show: 100, hide: 100 }"
      triggers="hover"
    ></b-tooltip>
  </div>
</template>

<script>
export default {
  name: "LinkContainer",
  data () {
    return {
      isTextCopied: false,
      copyButtonId: "copy-btn-202408081240",
      linkContainerId: "link-container-202410101239",
      staticTextDefault: {
        copyCallLabel: "Copy Link",
        copiedCallLabel: "Link Copied"
      }
    }
  },
  props: {
    url: {
      type: String,
      required: true
    },
    showCopyButton: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    getTooltipTarget () {
      return this.showCopyButton ? this.copyButtonId : this.linkContainerId
    },
    getLinkContainerClass () {
      const defaultClass = "link-container"
      return this.showCopyButton ? defaultClass : `${defaultClass} cursor-pointer`
    }
  },
  methods: {
    copyCallUrl () {
      const callUrl = window.location.href
      this.copyText(callUrl)
    },
    copyExternalShareUrl () {
      if (!this.showCopyButton) this.copyText(this.url)
    },
    async copyText (text) {
      await navigator.clipboard.writeText(text)
      this.isTextCopied = true
      await this.sleep(2000)
      this.isTextCopied = false
    }
  }
}
</script>

<style lang="scss" scoped>

.link-container {
  box-shadow: 4px 4px 20px 0px #0000000D;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  padding: 8px 24px;
}

.link-label{
  font-size: 12px;
  line-height: 14.4px;
  color: #AAABBA
}

.link-url {
  font-size: 16px;
  line-height: 19.2px;
  color: #2A2D52;
  display: -webkit-box;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

</style>
