<template>
  <div>
    <b-table
      :items="summary.call_flow"
      :fields="getFields"
      :sticky-header="stickyHeader"
    >
      <!-- Name -->
      <template v-slot:cell(name)="data">
        <div :class="getIndentation(data.item)">
          {{ data.item.name }}
        </div>
      </template>

      <!-- Covered + Text-Match -->
      <template v-slot:cell(covered)="data">
        <div class="text-center" v-if="getClasses(data.item)">
          <img :src="`/img/icons/${getClasses(data.item)}`"/>
        </div>

        <!--callItem transcript data-->
        <div v-if="!!itemTranscriptDataAvailable(data.item)"
             class="w-100"
        >
          <text-match-progress-indicator
            v-if="isOpenFromCallViewSummary && transcriptDataLoading"
          />
          <div v-else>
            <real-time-assistant-arc-pie
              v-if="isValidNumber(getItemTranscriptData(data.item.call_item.id).talking_share)"
              :label="staticText.talkingShareLabel"
              label-placement="right"
              :value="getItemTranscriptData(data.item.call_item.id).talking_share"
              :size="40"
            ></real-time-assistant-arc-pie>
            <real-time-assistant-arc-pie
              v-if="isValidNumber(getItemTranscriptData(data.item.call_item.id).talking_speed)"
              :label="staticText.talkingSpeedLabel"
              label-placement="right"
              :value="getItemTranscriptData(data.item.call_item.id).talking_speed"
              :inner-text="'' + getItemTranscriptData(data.item.call_item.id).talking_speed.toFixed(0)"
              :total="220"
              :size="40"
            ></real-time-assistant-arc-pie>
            <real-time-assistant-arc-pie
              v-if="showTextMatch && isValidNumber(getItemTranscriptData(data.item.call_item.id).text_similarity)"
              :label="staticText.textSimilarityLabel"
              label-placement="right"
              :value="getItemTranscriptData(data.item.call_item.id).text_similarity"
              :size="40"
            ></real-time-assistant-arc-pie>
          </div>
        </div>
      </template>

      <!-- bao value -->
      <template v-slot:head(bao_value)="data">
        <div id="bao-value-header-202012241533">
          {{ data.label }}
        </div>
      </template>
      <template v-slot:cell(bao_value)="data">
        <div :id="'bao-value-' + data.item.call_item.id"
             v-if="hasBaoValue(data)"
        >
          {{ data.item.call_item.bao_value }}
        </div>
      </template>

      <!-- Notes/Responses -->
      <template v-slot:cell(notes)="data">

        <p v-for="(objection, index) in getLoadedAdditionalData(data.item)"
           :key="'additional-data-' + index"
        >
          <img :src="`/img/icons/${getClasses(objection)}`" class="p-0 mr-2"/>
          {{ objection.selected_choice ? objection.selected_choice.name : "Unknown" }}
        </p>

        <!--display counterpart icon, crm logo and linked field label if item was covered-->
        <div v-if="wasLinkedFiledCovered(data)"
             class="mb-2"
        >
          <i :class="counterpartAppInfo.getCounterpartIcon(getCounterpartType(data))"></i>
          <component
            :is="getCrmLogo(getCRMService(data))"
            class="crm-logo crm-logo__slate ml-1"
          />
          {{ getFieldDefinitionLabel(data) }}:
          <div v-for="(item, index) in getAnswers(data.item)"
               :key="'linked-field-answers-' + index"
          >
            <ul>
              <i class="far fa-check-square"></i>
              {{ item.text }}
            </ul>

          </div>
        </div>

        <!-- answers -->
        <div v-else class="mb-2">
          <div v-for="(item, index) in getAnswers(data.item)"
               :key="'answers-' + index"
          >
            <i class="far fa-check-square"></i>
            {{ item.text }}{{ item.has_importance ? (" - " + item.importance) : "" }}
          </div>
        </div>

        <!-- notes -->
        <div v-if="!!getStrippedNotes(data.item)">
          {{staticText.notesLabel}}:
          <span v-html="getNotes(data.item)"></span>
        </div>

      </template>
    </b-table>
  </div>
</template>

<script>

import counterpartAppInfo from "@/apps/counterpart"
import RealTimeAssistantArcPie from "@/apps/call/RealTimeAssistantComponents/RealTimeAssistantArcPie"
import { strip } from "@/apps/call_history"
import { mapGetters } from "vuex"
import TextMatchProgressIndicator from "./TextMatchProgressIndicator"
import CrmLogoMixin from "@/apps/call_history/CallSummaryComponents/CrmLogoMixin"

export default {
  name: "CallSummaryTable",
  mixins: [CrmLogoMixin],
  components: {
    RealTimeAssistantArcPie,
    TextMatchProgressIndicator
  },
  data () {
    return {
      counterpartAppInfo,
      staticTextDefault: {
        coveredLabel: "Covered",
        itemNameLabel: "Item Name",
        responsesLabel: "Notes/Responses",
        baoValueMappingsLabel: "bao value",
        talkingShareLabel: "Talking Share",
        talkingSpeedLabel: "Tempo (WPM)",
        textSimilarityLabel: "Text-Match",
        notesLabel: "Notes"
      }
    }
  },
  props: {
    summary: {
      type: Object,
      required: true
    },
    callTranscriptData: {
      type: Object,
      default: null,
      required: false
    },
    stickyHeader: {
      type: String, // provide a valid css value for table height in order to make table scrollable
      default: null
    },
    isOpenFromCallViewSummary: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      canUseBaoValue: "auth/canUseBaoValue",
      showTextMatch: "auth/showTextMatch",
      transcriptDataLoading: "callAudioStore/getTranscriptDataLoading"
    }),
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    getFields () {
      const fields = [
        {
          key: "name",
          label: this.staticText.itemNameLabel,
          sortable: false,
          thClass: "bg-light"
        },
        {
          key: "covered",
          label: this.staticText.coveredLabel,
          sortable: false,
          thClass: "text-center bg-light"
        },
        {
          key: "notes",
          label: this.staticText.responsesLabel,
          sortable: false,
          thClass: "bg-light"
        }
      ]
      if (this.baoValueAvailable) {
        fields.splice(2, 0, {
          key: "bao_value",
          label: this.staticText.baoValueMappingsLabel,
          thClass: "bg-light"
        })
      }
      return fields
    },
    baoValueAvailable () {
      return this.canUseBaoValue && this.summary.bao_values && this.summary.bao_values.length > 0
    }

  },
  methods: {
    getIndentation (item) {
      let indentation = ""
      if (item.type === "objection" || item.type === "default") indentation = "ml-3"
      if (item.parent) indentation += "ml-5"
      return indentation
    },
    getClasses (item) {
      if (this.isObjection(item)) {
        return "summary-objections-icon.svg"
      } else if (this.isDynPlaybook(item)) {
        return "summary-document-icon.svg"
      } else if (this.wasEdited(item)) return "summary-success-icon.svg"
      return null
    },
    isObjection (item) {
      return item.type === "objection"
    },
    isDynPlaybook (item) {
      return item.type === "default"
    },
    wasEdited (item) {
      const noteExists = !!this.getNotes(item) && this.getNotes(item).length > 0
      const answersExists = !!this.getAnswers(item) && this.getAnswers(item).length > 0
      const addPlaybookExists = !!this.getLoadedAdditionalData(item) && this.getLoadedAdditionalData(item).length > 0
      return noteExists || answersExists || addPlaybookExists
    },
    getNotes (item) {
      if (!item || !item.call_item) return
      return item.call_item.note
    },
    strip (html) {
      return strip(html)
    },
    getStrippedNotes (item) {
      if (this.getNotes(item)) return this.strip(this.getNotes(item))
    },
    getAnswers (item) {
      if (!item || !item.call_item || !item.call_item.answers) return
      return item.call_item.answers
    },
    getLoadedAdditionalData (item) {
      if (!item || !item.call_item || !item.call_item.loaded_additional_data) return
      return item.call_item.loaded_additional_data
    },
    getFieldDefinitionLabel (data) {
      return data.item.call_item.linked_field.field_definition.label
    },
    wasLinkedFiledCovered (data) {
      return !!data.item.call_item && !!data.item.call_item.linked_field
    },
    getCRMService (data) {
      try {
        return data.item.call_item.linked_field.crm_object_link.service_key
      } catch (error) {
        return ""
      }
    },
    hasBaoValue (data) {
      return data.item && data.item.call_item && data.item.call_item.bao_value
    },
    getCounterpartType (data) {
      return data.item.call_item.linked_field.crm_object_link.label
    },
    itemTranscriptDataAvailable (playbookItem) {
      if (playbookItem.call_item && this.callTranscriptData) {
        return this.callTranscriptData.call_items_transcripts.some(item => item.call_item === playbookItem.call_item.id)
      }
      return false
    },
    getItemTranscriptData (callItemId) {
      return this.callTranscriptData.call_items_transcripts.filter(item => item.call_item === callItemId)[0]
    }
  }

}
</script>

<style scoped lang="scss">

.crm-logo {
  width: 20px;
  height: 20px;
  margin-bottom: 3px;
}

</style>
