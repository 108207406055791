<template>
  <div>
    <progress-indicator
      :small="true"
    >
      <template v-slot:label>
        <div class="ml-2 py-3 font-size-12 w-80">
          <p class="mb-1">{{staticText.calculatingTextMatchLabel}}</p>
          <p class="mb-0">{{staticText.doNotRefreshLabel}}</p>
        </div>
      </template>
    </progress-indicator>
  </div>
</template>

<script>
import ProgressIndicator from "../../base/ProgressIndicator"

export default {
  name: "TextMatchProgressIndicator",
  components: {
    ProgressIndicator
  },
  data () {
    return {
      staticTextDefault: {
        calculatingTextMatchLabel: "Calculating Text-Match...",
        doNotRefreshLabel: "Please don’t refresh this page until everything has loaded."
      }
    }
  },
  props: {
    isOpenFromCallViewSummary: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  }
}
</script>

<style>

</style>
