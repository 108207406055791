<template>
  <div>
    <button
      id="link-copy-btn-202409181558"
      :class="getButtonClass"
      @click="$emit('trigger-action')"
    >
      <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="!showSuccessMessage && showInitialIcon" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M5.85 12.5A2.553 2.553 0 013.3 9.95V3.8H2.25C1.34 3.8.6 4.54.6 5.45v8.1c0 .91.74 1.65 1.65 1.65h7.5c.91 0 1.65-.74 1.65-1.65V12.5H5.85z" fill="#2A2D52" fill-opacity=".8"/><path d="M13.8 2.45A1.65 1.65 0 0012.15.8h-6.3A1.65 1.65 0 004.2 2.45v7.5c0 .911.739 1.65 1.65 1.65h6.3a1.65 1.65 0 001.65-1.65v-7.5z" fill="#2A2D52" fill-opacity=".8"/></svg>
      <svg width="46" height="32" viewBox="0 0 46 32" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="showSuccessMessage" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M3 16.003l13.333 13.334L43 2.67" stroke="#fff" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/></svg>
      {{ actionLabel }}
      <progress-indicator
        v-if="isLoading"
        class="ml-2 mr-0"
        :small="true"
        :show-loading-label="false"
      />
    </button>
    <b-tooltip
      v-if="isDisabled && !hideTooltip"
      target="link-copy-btn-202409181558"
      :title="tooltipLabel"
      :delay="{ show: 100, hide: 400 }"
      triggers="hover"
    ></b-tooltip>
  </div>
</template>

<script>
import ProgressIndicator from "@/apps/base/ProgressIndicator.vue"

export default {
  name: "ShareActionButton",
  components: { ProgressIndicator },
  data () {
    return {
      staticTextDefault: {
        createPasswordLabel: "Create a password to continue"
      }
    }
  },
  props: {
    showInitialIcon: {
      type: Boolean,
      default: true
    },
    showSuccessMessage: {
      type: Boolean,
      required: true
    },
    actionLabel: {
      type: String,
      required: true
    },
    btnClass: {
      type: String,
      default: "btn-secondary"
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    disabledLabel: {
      type: String
    },
    hideTooltip: {
      type: Boolean,
      default: false
    },
    disabledBtnClass: {
      type: String,
      default: "button-disabled"
    },
    extraBtnClasses: {
      type: String,
      default: "default-action-btn"
    }
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    getButtonClass () {
      const defaultClasses = "btn share-action-button"
      let extraClasses = ""
      extraClasses += ` ${this.btnClass} ${this.extraBtnClasses}`
      if (this.showSuccessMessage) extraClasses += " action-triggered"
      if (this.isDisabled) extraClasses += ` ${this.disabledBtnClass}`

      return `${defaultClasses} ${extraClasses}`
    },
    tooltipLabel () {
      return this.disabledLabel ? this.disabledLabel : this.staticText.createPasswordLabel
    }
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>

.share-action-button {
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 16px;
    height: 18px;
    margin-right: 11.5px;
  }
  &.default-action-btn {
    min-width: 120px;
    border-radius: 100px;
    padding: 14px 16px;
  }
  &.action-triggered {
    pointer-events: none;
    border: none;
    background-color: transparent;
    color: rgba(31, 170, 9, 1) !important;
    svg {
      height: 11px;
      path {
        stroke: rgba(31, 170, 9, 1);
      }
    }
  }
  &.button-disabled {
    background-color: $slate06;
    color: $slate40 !important;
    svg {
      path {
        fill: $slate40;
      }
    }
  }
}

</style>
