<template>
  <b-modal
    v-model="regenerateTranscriptModal"
    body-class="pt-3 px-20px"
    hide-footer
  >
    <template v-slot:modal-header-close>
      <img src="../../../../../../public/img/icons/close-icon.svg"/>
    </template>
    <template v-slot:modal-title>
      <div class="modalTitle">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:class="'mr-2'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M7.048 13.75a.4.4 0 00.316-.645L4.51 9.409a.4.4 0 00-.633 0l-2.874 3.697a.4.4 0 00.316.645h1.225c.22 0 .4.18.4.4v.787c0 1.965 1.643 3.563 3.664 3.563h7.329c2.02 0 3.664-1.598 3.664-3.563v-.787a.4.4 0 00-.4-.4h-1.643a.4.4 0 00-.4.4v.787c0 .655-.548 1.188-1.221 1.188H6.607c-.673 0-1.221-.533-1.221-1.188v-.787c0-.22.179-.4.4-.4h1.262zM12.952 6.15a.4.4 0 00-.316.645l2.856 3.697a.4.4 0 00.632 0l2.874-3.696a.4.4 0 00-.316-.646h-1.225a.4.4 0 01-.4-.4v-.787c0-1.965-1.643-3.563-3.664-3.563H6.064C4.044 1.4 2.4 2.998 2.4 4.963v.787c0 .221.18.4.4.4h1.643a.4.4 0 00.4-.4v-.787c0-.654.548-1.188 1.221-1.188h7.329c.673 0 1.221.534 1.221 1.188v.787a.4.4 0 01-.4.4h-1.262z" fill="#555775"/></svg>
        {{ staticText.regenerateTranscriptAndSummary }}
      </div>
    </template>

    <div class="regenerate-transcript">
      <h1 class="mb-4">
        {{ staticText.regenerateTranscriptMessage }}
      </h1>

      <!--  Audio input language select      -->
      <AudioInputLanguageSelector
        :label="staticText.audioInputLanguage"
        :placeholder="staticText.selectAudioLanguage"
        class="mb-3"
        @select="selectInputLanguage"
      />

      <button
        class="btn btn-primary btn-regenerate"
        @click="regenerateTranscriptAndSummary"
        :disabled="!audioInputLanguage"
      >
        {{ staticText.regenerateLabel }}
      </button>
    </div>
  </b-modal>
</template>

<script>
import { mapActions } from "vuex"
import AudioInputLanguageSelector from "@/apps/user/profile/AudioInputLanguageSelector"

export default {
  name: "RegenerateTranscriptAndSummaryModal",
  components: {
    AudioInputLanguageSelector
  },
  data () {
    return {
      staticTextDefault: {
        regenerateTranscriptAndSummary: "Regenerate Transcript & Summary",
        regenerateLabel: "Regenerate",
        regenerateTranscriptMessage: "If the transcript’s language is not correct, select the correct input language (the language of the audio file) and have the transcript and summary generated again.",
        audioInputLanguage: "Audio Input Language",
        selectAudioLanguage: "Select Language"
      },
      regenerateTranscriptModal: false,
      audioInputLanguage: null
    }
  },
  props: {
    callId: {
      required: true
    }
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](
        this.$options.name,
        this.staticTextDefault
      )
    }
  },
  methods: {
    ...mapActions({
      getRegeneratedTranscriptsAndSummary: "callAudioStore/getRegeneratedTranscriptsAndSummary"
    }),
    openModal () {
      this.regenerateTranscriptModal = true
    },
    selectInputLanguage (language) {
      if (language) {
        this.audioInputLanguage = language.value
      }
    },
    regenerateTranscriptAndSummary () {
      this.getRegeneratedTranscriptsAndSummary({
        callId: this.callId,
        audioInputLanguage: this.audioInputLanguage
      })
      this.regenerateTranscriptModal = false
    }
  }
}
</script>

<style lang="scss" scoped>

.modalTitle {
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
}

.regenerate-transcript {
  h1 {
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    color: $slate60;
  }

  button.btn-regenerate {
    float: right;
    padding: 12px !important;
  }
}

</style>
