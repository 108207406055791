<template>
  <div class="d-flex align-items-center justify-content-center">
    <div v-if="!loading">
      <b-form-checkbox
        v-model="checkboxValue"
        size="sm"
        name="check-button"
        class="d-flex"
        :disabled="disabled"
        :switch="switchStyle"
        @change="$emit('input', checkboxValue)"
      >
        <div  class="ml-2 checkbox-label">
          {{ label }}
        </div>
      </b-form-checkbox>
    </div>
    <progress-indicator
      v-else
      :small="true"
      :showLoadingLabel="false"
    />
  </div>
</template>
<script>
import ProgressIndicator from "../base/ProgressIndicator.vue"

export default {
  name: "BaoCheckbox",
  components: {
    ProgressIndicator
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ""
    },
    switchStyle: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      preId: "",
      checkboxValue: false
    }
  },
  watch: {
    value (val) {
      this.checkboxValue = val
    }
  },
  mounted () {
    this.preId = Math.ceil(Math.random() * Math.random() * Date.now()) + ""
    this.checkboxValue = this.value
  }
}

</script>
<style scoped lang="scss">

.checkbox-label{
    font-size: 16px;
    color: #2A2D52;
    white-space: nowrap;
    margin: 0;
    padding: 0;
}
</style>
